import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { useParams } from "react-router-dom";
import Editor from "./Editor/Editor";
import { Image } from "./Image/Image";
import { slideAction } from "../../../store/slideSlice";
// import { slideAPI } from "../../../store/api";
import { Shape } from "./shapes/Shape";
import { getSnapshot } from "../../../utils/utils";
import { v4 } from "uuid";
import { slideAPI } from "../../../store/api";
import { emitSlideAdd } from "../../../Socket/Socket";
import { useParams } from "react-router-dom";
import { getUpdatedSlides, modifyMasterSlideData } from "../../../utils/helper";
import LayoutEnum from "../../../utils/layoutEnum";

export const SlideContents = () => {
  // const [addResizer, removeResizer] = useResizer();
  const { addNewSlideInDatabase } = slideAPI
  const currentSlideId = useSelector((state) => state.slide.currentSlideId);
  // const slidesData = useSelector((state) => state.slide);
  // const mirrorSlides = useSelector((state) => state.slide.mirrorSlides);
  const textBoxIds = useSelector((state) => currentSlideId && state.slide.slides[currentSlideId]?.textBoxIds);
  const imageIds = useSelector((state) => currentSlideId && state.slide.slides[currentSlideId]?.imageIds);
  // const textBoxes = useSelector((state) => state.slide.slides[state.slide.currentSlideId].textBox);
  const shapes = useSelector((state) => currentSlideId && state.slide.slides[currentSlideId]?.shapes)

  const presentMode = useSelector((state) => state.slide.presentMode);
  const slideBackgroundColor = useSelector((state) => currentSlideId && state.slide.slides[currentSlideId]?.slideBackgroundColor);
  const dispatch = useDispatch();
  const { id } = useParams();
  const isReadOnly = useSelector((state) => state.slide.isReadOnly);
  const isMasterSlide = useSelector((state) => state.slide.isMasterSlide);
  const existingSlides = useSelector((state) => state.slide.slides);
  const filteredSlides = existingSlides && Object.keys(existingSlides).filter(id => !id.toLowerCase().includes("master"));
  let masterSlideId = useSelector(state => state.slide.masterSlideId);
  const mirrorSlides = useSelector((state) => state.slide.mirrorSlides);

  //  console.log(id);
  // const removeResizer = () => {
  //   document.querySelectorAll(".resizer").forEach((e) => e.remove());
  // }

  useEffect(() => {
    if (imageIds && imageIds.length > 0 && !presentMode && !isMasterSlide && !currentSlideId.includes('master')) {
      getSnapshot().then((dataUrl) => {
        dispatch(
          slideAction.saveMirror({
            dataId: currentSlideId,
            mirrorSlideDataUrl: dataUrl,
          })
        );
      });
    }
  }, [imageIds]);

  useEffect(() => {
    if (currentSlideId && !presentMode && !isMasterSlide && !currentSlideId.includes('master')) {
      getSnapshot().then((dataUrl) => {
        dispatch(
          slideAction.saveMirror({
            dataId: currentSlideId,
            mirrorSlideDataUrl: dataUrl,
          })
        );
      });
    }
  }, [currentSlideId]);

  const handleAddNewSlide = () => {
    const slideId = "slide" + v4();
    const titleId = "default-addSlidelayoutTitle" + v4();
    const textId = "default-addSlidelayoutText" + v4();
    const masterId = Object.keys(existingSlides).find(slideId => 
      slideId.toLowerCase().includes('master') &&
      !Object.values(LayoutEnum).some(layout => 
        slideId.toLowerCase().includes(layout.toLowerCase())
      )
    ) || null;
    let newSlideData = {
      slideBackgroundColor: "#ffffff",
      textBoxIds: [titleId, textId],
      textBox: {
        [titleId]: {
          delta: {
            ops: [{ insert: "Click to add title" }, { attributes: { header: 1 }, insert: "\n" }]
          },
          styles: { width: '93%', height: '35%', padding: "5px 10px", position: 'absolute', top: '15%', left: '3.5%', align: 'center', zIndex: 100 },
        },
        [textId]: {
          delta: {
            ops: [{ insert: "Click to add subtitle", attributes: { color: "#5a5b60" } }, { attributes: { align: 'center', header: 2 }, insert: "\n" }]
          },
          styles: { width: '93%', height: '15%', position: 'absolute', top: '52%', left: '3.5%', overflow: 'visible', align: 'center', zIndex: 100 },
        }
      },
      imageIds: [],
      image: {},
      shapes: {},
      speakerNotes: "",
      additionalData: {
        isSlideColorChanged: false,
      }
    }
    let masterSlideData = modifyMasterSlideData(existingSlides?.[masterId] || null);
    if (masterSlideData) {
      newSlideData = {
        ...newSlideData,
        slideBackgroundColor: masterSlideData.slideBackgroundColor,
        textBoxIds: [...newSlideData.textBoxIds, ...masterSlideData.textBoxIds],
        textBox: { ...newSlideData.textBox, ...masterSlideData.textBox },
        imageIds: [...newSlideData.imageIds, ...masterSlideData.imageIds],
        image: { ...newSlideData.image, ...masterSlideData.image },
        shapes: { ...newSlideData.shapes, ...masterSlideData.shapes },
        speakerNotes: newSlideData.speakerNotes,
      };
    }
    dispatch(slideAction.addSlide({ slideId, slideData: newSlideData }));
    dispatch(slideAction.setCurrentSlide(slideId));
    setTimeout(
      () =>
        getSnapshot().then((dataUrl) => {
          dispatch(
            slideAction.saveMirror({
              dataId: slideId,
              mirrorSlideDataUrl: dataUrl,
            })
          );
          emitSlideAdd({ roomId: id, mirrorData: { dataId: slideId, mirrorSlideDataUrl: dataUrl }, slideData: { slideId, slideData: newSlideData } });
          const updatedSlides = getUpdatedSlides({ slideId, slideData: newSlideData }, 'slide', existingSlides);
          const data = {
            slides: updatedSlides,
            mirrorSlides: mirrorSlides
          }
          addNewSlideInDatabase(id, slideId, newSlideData, dispatch, data);
        }),
      0
    );
  }
  const pointerEventsStyle = {
    pointerEvents: isReadOnly ? "none" : "all"
  };

  return (
    <>
      {currentSlideId && filteredSlides.length > 0 && <div
        id="slide01"
        className="slide-container"
        style={{ ...pointerEventsStyle, backgroundColor: slideBackgroundColor }}
      >
        {textBoxIds && textBoxIds.length > 0 &&
          textBoxIds.map((quillId) => (
            <Editor
              quillId={quillId}
              presentMode={presentMode}
              currentSlideId={currentSlideId}
              key={quillId}
            />
          ))}
        {imageIds && imageIds.length > 0 &&
          imageIds.map((imgId) => (
            <Image key={imgId} imgId={imgId} />
          ))}
        {shapes && Object.keys(shapes).length !== 0 && Object.keys(shapes).map((shapeId) => <Shape key={shapeId} shapeId={shapeId} />)}
      </div>}
      {filteredSlides.length === 0 &&
        < div className="add-new-slide" onClick={handleAddNewSlide}>
          <p>Click to add new slide</p>
        </div >

      }
    </>
  );
};
