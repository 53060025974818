import { useDispatch, useSelector } from "react-redux";
import addSlide from "../../assets/add.png";
import { useState, useEffect } from "react";
import insertImage from "../../assets/insertImage.svg";
import textBox from "../../assets/text.svg";
import { slideAction } from "../../store/slideSlice";
import classes from "./Toolbar.module.css";
import { LayoutDropDown } from "./LayoutDropDown/LayoutDropDown";
import { v4 } from "uuid";
import { ColorPickerDropDown } from "./ColorPickerDropDown/ColorPickerDropDown";
import { ShapesDropdown } from "./ShapesDropDown/ShapesDropdown";
import { getSnapshot } from "../../utils/utils";
import { useParams } from "react-router-dom";
import { emitElementAdd, emitSlideAdd } from "../../Socket/Socket";
import { slideAPI } from "../../store/api";
import AddIcon from '@mui/icons-material/Add';
import AddTextIcon from '@mui/icons-material/FormatShapesSharp';
import AddImage from '@mui/icons-material/AddPhotoAlternateOutlined';
import { Tooltip } from "antd";
import MasterSlide from "../MasterSlide";
import { getUpdatedSlides, modifyMasterSlideData, filterOtherLayoutElements, removeDuplicates } from "../../utils/helper";
import LayoutEnum from "../../utils/layoutEnum";
import layoutsArray from "../../utils/layoutStructure";
export const Toolbar = () => {

  const {
    addNewSlideInDatabase,
    createNewElementInDatabase } = slideAPI;

  const dispatch = useDispatch();
  const { id } = useParams();
  const isMasterSlide = useSelector((state) => state.slide.isMasterSlide);
  let masterSlideId = useSelector(state => state.slide.masterSlideId)
  let currentSlideId = useSelector((state) => state.slide.currentSlideId)
  const currentSlideData = useSelector((state) => currentSlideId && state.slide.slides[currentSlideId]);
  const existingSlides = useSelector((state) => state.slide.slides);
  const mirrorSlides = useSelector((state) => state.slide.mirrorSlides);
  const isReadOnly = useSelector((state) => state.slide.isReadOnly);
  const [defaultLayoutTitle, setDefaultLayoutTitle] = useState("");
  const [defaultLayoutTextBox, setDefaultLayoutTextBox] = useState("");
  const [defaultLayoutTextBox2, setDefaultLayoutTextBox2] = useState("");
  const [textBoxesWithoutDefaultLayoutText, setTextBoxesWithoutDefaultLayoutText] = useState({});

  useEffect(() => {
    if (currentSlideData && currentSlideData.textBox) {
      const filteredTextBoxes = Object.entries(currentSlideData.textBox)
        .filter(([key, textBox]) => {
          const data = textBox?.delta?.ops[0]?.insert;
          return (
            key.includes('default') &&
            data &&
            data.trim().length > 0 &&
            data.slice(0, 8) !== "Click to"
          );
        })
        .reduce((textBoxes, [key, textBox]) => {
          textBoxes[key] = textBox;
          return textBoxes;
        }, {});
      setTextBoxesWithoutDefaultLayoutText(filteredTextBoxes);
      const objectKeys = Object.keys(filteredTextBoxes);
      let title = null;
      let text = null;
      let textTwo = null;
      objectKeys.forEach((key) => {
        const data = currentSlideData.textBox[key]?.delta?.ops[0]?.insert;
        if (key.includes('default') && key.includes("Title")) {
          title = data;
        }
        if (key.includes('default') && key.includes("Text")) {
          text = data;
        }
        if (key.includes('default') && key.includes("layoutTwo")) {
          textTwo = data;
        }
      });
      setDefaultLayoutTitle(title);
      setDefaultLayoutTextBox(text);
      setDefaultLayoutTextBox2(textTwo);
    }
  }, [currentSlideData?.textBox]);

  let draw = false,
    clickCount = 0,
    creatingTextbox = false,
    tempBox,
    lockScreen,
    slideContainer,
    slideContent,
    x1,
    y1,
    x2,
    y2;
  const pageWidth = window.screen.width > 2200 ? 1800 : window.screen.width > 1540 ? 1200 : window.screen.width > 1200 ? 960 : window.screen.width > 992 ? 848 : window.screen.width > 768 ? 700 : window.screen.width > 490 ? 490 : 300;
  const pageHeight = window.screen.width > 2200 ? 1020 : window.screen.width > 1540 ? 700 : window.screen.width > 1200 ? 540 : window.screen.width > 992 ? 500 : window.screen.width > 768 ? 400 : window.screen.width > 490 ? 290 : 180;

  const addSlideHandler = () => {
    const slideId = "slide" + v4();
    const titleId = "default-newSlidelayoutTitle-" + v4();
    const textId = "default-newSlidelayoutText-" + v4();
    let slideData = {
      slideBackgroundColor: "#fff",
      textBoxIds: [titleId, textId],
      textBox: {
        [titleId]: {
          delta: {
            ops: [{ insert: "Click to add title" }, { insert: "\n" }],
          },
          styles: {
            width: "93%",
            height: "11%",
            padding: "5px 10px",
            position: "absolute",
            top: "8%",
            left: "3.5%",
          },
        },
        [textId]: {
          delta: {
            ops: [{ insert: "Click to add text", attributes: { color: "#5a5b60" } }, { insert: "\n" }],
          },
          styles: {
            width: "93%",
            height: "70%",
            padding: "5px 10px",
            position: "absolute",
            top: "21%",
            left: "3.5%",
            overflow: "visible",
          },
        },
      },
      imageIds: [],
      image: {},
      shapes: {},
      speakerNotes: "",
      additionalData: {
        isSlideColorChanged: false,
      }
    };
    const masterId = Object.keys(existingSlides).find(id =>
      id.toLowerCase().includes('master') &&
      !Object.values(LayoutEnum).some(enumValue => id.toLowerCase().includes(enumValue.toLowerCase()))
    );
    let masterSlideData = modifyMasterSlideData(existingSlides[masterId] || null);
    if (masterSlideData) {
      slideData = {
        ...slideData,
        slideBackgroundColor: masterSlideData.slideBackgroundColor,
        textBoxIds: [...slideData.textBoxIds, ...masterSlideData.textBoxIds],
        textBox: { ...slideData.textBox, ...masterSlideData.textBox },
        imageIds: [...masterSlideData.imageIds],
        image: { ...slideData.image, ...masterSlideData.image },
        shapes: { ...slideData.shapes, ...masterSlideData.shapes },
        speakerNotes: slideData.speakerNotes,
      };
    }
    const newSlideData = {
      slideId,
      slideData,
    };
    dispatch(slideAction.addSlide(newSlideData));
    dispatch(slideAction.setCurrentSlide(slideId));
    setTimeout(
      () =>
        getSnapshot().then((dataUrl) => {
          dispatch(
            slideAction.saveMirror({
              dataId: slideId,
              mirrorSlideDataUrl: dataUrl,
            })
          );
          emitSlideAdd({ roomId: id, mirrorData: { dataId: slideId, mirrorSlideDataUrl: dataUrl }, slideData: { slideId, slideData } });
          const updatedSlides = getUpdatedSlides(newSlideData, 'slide', existingSlides);
          const data = {
            slides: updatedSlides,
            mirrorSlides: mirrorSlides
          }
          addNewSlideInDatabase(id, slideId, slideData, dispatch, data);
        }),
      0
    );
  };

  const addSlideLayout = (slideLayout) => {
    let slideId = "slide" + v4();
    let titleId = "default-layoutTitle-" + v4();
    let textId = "default-layoutText-" + v4();
    let text2Id = "default-layoutTwo-" + v4();
    if (currentSlideId) {
      slideId = currentSlideId;
    }
    let slideData;
    switch (slideLayout) {
      case LayoutEnum.BLANK:
        slideData = {
          slideBackgroundColor: currentSlideData ? currentSlideData.slideBackgroundColor : '#fff',
          textBoxIds: Object.keys(textBoxesWithoutDefaultLayoutText) ? Object.keys(textBoxesWithoutDefaultLayoutText) : {},
          textBox: textBoxesWithoutDefaultLayoutText ? textBoxesWithoutDefaultLayoutText : {},
          imageIds: currentSlideData ? Object.keys(currentSlideData.image) : [],
          image: currentSlideData?.image ? currentSlideData.image : {},
          shapes: currentSlideData?.shapes ? currentSlideData.shapes : {},
          speakerNotes: currentSlideData?.speakerNotes ? currentSlideData.speakerNotes : {},
          additionalData: currentSlideData?.additionalData ? currentSlideData?.additionalData : {},
        };
        // slideId = `layoutBlank-${slideId}`;
        break;
      case LayoutEnum.TITLE_BODY:
        const filteredLayoutTextBoxes = Object.entries(textBoxesWithoutDefaultLayoutText)
          .filter(([key]) => {
            const isLayoutTitleBox = key.includes('default') && key.includes("layoutTitle");
            const isLayoutTextBox = key.includes('default') && key.includes("layoutText");
            return !(isLayoutTextBox || isLayoutTitleBox);
          })
          .reduce((textBoxes, [key, textBox]) => {
            textBoxes[key] = textBox;
            return textBoxes;
          }, {});
        const AdditonalTextBoxeskeys = Object.keys(filteredLayoutTextBoxes);
        slideData = {
          slideBackgroundColor: currentSlideData ? currentSlideData.slideBackgroundColor : '#fff',
          textBoxIds: [titleId, textId, ...AdditonalTextBoxeskeys],
          textBox: {
            ...filteredLayoutTextBoxes,
            [titleId]: {
              delta: {
                ops: [{ insert: defaultLayoutTitle ? defaultLayoutTitle : "Click to add title" }, { insert: "\n" }],
              },
              styles: {
                width: "93%",
                height: "11%",
                padding: "5px 10px",
                position: "absolute",
                top: "8%",
                left: "3.5%",
              },
            },
            [textId]: {
              delta: {
                ops: [{ insert: defaultLayoutTextBox ? defaultLayoutTextBox : "Click to add text", attributes: { color: "#5a5b60" } }, { insert: "\n" }],
              },
              styles: {
                width: "93%",
                height: "70%",
                padding: "5px 10px",
                position: "absolute",
                top: "21%",
                left: "3.5%",
                overflow: "visible",
              },
            },
          },
          imageIds: currentSlideData ? Object.keys(currentSlideData.image) : [],
          image: currentSlideData?.image ? currentSlideData.image : {},
          shapes: currentSlideData?.shapes ? currentSlideData.shapes : {},
          speakerNotes: currentSlideData?.speakerNotes ? currentSlideData.speakerNotes : {},
          additionalData: currentSlideData?.additionalData ? currentSlideData?.additionalData : {}
        };
        // slideId = `layout01-${slideId}`;
        break;
      case LayoutEnum.TITLE_TWO_COLUMNS:
        const filteredLayoutTwoTextBoxes = Object.entries(textBoxesWithoutDefaultLayoutText)
          .filter(([key]) => {
            const isLayoutTextBox = key.includes('default') && key.includes("layout");
            // console.log("isLayoutTextBox", isLayoutTextBox)
            return !(isLayoutTextBox);
          })
          .reduce((textBoxes, [key, textBox]) => {
            textBoxes[key] = textBox;
            return textBoxes;
          }, {});
        const AdditonalTextBoxeskeysTwo = Object.keys(filteredLayoutTwoTextBoxes);
        slideData = {
          slideBackgroundColor: currentSlideData ? currentSlideData.slideBackgroundColor : '#fff',
          textBoxIds: [titleId, textId, text2Id, ...AdditonalTextBoxeskeysTwo],
          textBox: {
            ...filteredLayoutTwoTextBoxes,
            [titleId]: {
              delta: {
                ops: [{ insert: defaultLayoutTitle ? defaultLayoutTitle : "Click to add title" }, { insert: "\n" }],
              },
              styles: {
                width: "93%",
                height: "11%",
                padding: "5px 10px",
                position: "absolute",
                top: "8%",
                left: "3.5%",
              },
            },
            [textId]: {
              delta: {
                ops: [{ insert: defaultLayoutTextBox ? defaultLayoutTextBox : "Click to add text", attributes: { color: "#5a5b60" } }, { insert: "\n" }],
              },
              styles: {
                width: "44%",
                height: "70%",
                padding: "5px 10px",
                position: "absolute",
                top: "21%",
                left: "3.5%",
                overflow: "visible",
              },
            },
            [text2Id]: {
              delta: {
                ops: [{ insert: defaultLayoutTextBox2 ? defaultLayoutTextBox2 : "Click to add text", attributes: { color: "#5a5b60" } }, { insert: "\n" }],
              },
              styles: {
                width: "44%",
                height: "70%",
                padding: "5px 10px",
                position: "absolute",
                top: "21%",
                left: "52.5%",
                overflow: "visible",
              },
            },
          },
          imageIds: currentSlideData ? Object.keys(currentSlideData.image) : [],
          image: currentSlideData.image ? currentSlideData.image : {},
          shapes: currentSlideData.shapes ? currentSlideData.shapes : {},
          speakerNotes: currentSlideData.speakerNotes ? currentSlideData.speakerNotes : {},
          additionalData: currentSlideData?.additionalData ? currentSlideData?.additionalData : {}
        };
        // slideId = `layout02-${slideId}`;
        break;
      case LayoutEnum.SECTION_HEADER:

        const filteredLayoutThreeTextBoxes = Object.entries(textBoxesWithoutDefaultLayoutText)
          .filter(([key]) => {
            const isLayoutTextBox = (key.includes('default')) && (key.includes('Title') || key.includes('title'));
            return !(isLayoutTextBox);
          })
          .reduce((textBoxes, [key, textBox]) => {
            textBoxes[key] = textBox;
            return textBoxes;
          }, {});
        const AdditonalTextBoxeskeysThree = Object.keys(filteredLayoutThreeTextBoxes);
        slideData = {
          slideBackgroundColor: currentSlideData ? currentSlideData.slideBackgroundColor : '#fff',
          textBoxIds: [titleId, ...AdditonalTextBoxeskeysThree],
          textBox: {
            ...filteredLayoutThreeTextBoxes,
            [titleId]: {
              delta: {
                ops: [{ insert: defaultLayoutTitle ? defaultLayoutTitle : "Click to add title" }, { attributes: { align: 'center' }, insert: "\n" }]
              },
              styles:
              {
                width: '93%',
                height: '15%',
                position: 'absolute',
                top: '52%',
                left: '3.5%',
                overflow: 'visible',
                align: 'center',
                zIndex: 100
              },
            },
          },
          imageIds: currentSlideData ? Object.keys(currentSlideData.image) : [],
          image: currentSlideData.image ? currentSlideData.image : {},
          shapes: currentSlideData.shapes ? currentSlideData.shapes : {},
          speakerNotes: currentSlideData.speakerNotes ? currentSlideData.speakerNotes : {},
          additionalData: currentSlideData?.additionalData ? currentSlideData?.additionalData : {}
        };
        // slideId = `layout03-${slideId}`;
        break;
      case LayoutEnum.TITLE_ONE_COLUMN:
        const filteredLayoutFourTextBoxes = Object.entries(textBoxesWithoutDefaultLayoutText)
          .filter(([key]) => {
            const isLayoutTitleBox = key.includes('default') && key.includes("layoutTitle");
            const isLayoutTextBox = key.includes('default') && key.includes("layoutText");
            return !(isLayoutTextBox || isLayoutTitleBox);
          })
          .reduce((textBoxes, [key, textBox]) => {
            textBoxes[key] = textBox;
            return textBoxes;
          }, {});
        const AdditonalTextBoxeskeysFour = Object.keys(filteredLayoutFourTextBoxes);
        slideData = {
          slideBackgroundColor: currentSlideData ? currentSlideData.slideBackgroundColor : '#fff',
          textBoxIds: [titleId, textId, ...AdditonalTextBoxeskeysFour],
          textBox: {
            ...filteredLayoutFourTextBoxes,
            [titleId]: {
              delta: {
                ops: [{ insert: defaultLayoutTitle ? defaultLayoutTitle : "Click to add title" }, { insert: "\n" }],
              },
              styles: {
                width: "93%",
                height: "11%",
                padding: "5px 10px",
                position: "absolute",
                top: "8%",
                left: "3.5%",
              },
            },
            [textId]: {
              delta: {
                ops: [{ insert: defaultLayoutTextBox ? defaultLayoutTextBox : "Click to add text", attributes: { color: "#5a5b60" } }, { insert: "\n" }],
              },
              styles: {
                width: "44%",
                height: "70%",
                padding: "5px 10px",
                position: "absolute",
                top: "21%",
                left: "3.5%",
                overflow: "visible",
              },
            },
          },
          imageIds: currentSlideData ? Object.keys(currentSlideData.image) : [],
          image: currentSlideData.image ? currentSlideData.image : {},
          shapes: currentSlideData.shapes ? currentSlideData.shapes : {},
          speakerNotes: currentSlideData.speakerNotes ? currentSlideData.speakerNotes : {},
          additionalData: currentSlideData?.additionalData ? currentSlideData?.additionalData : {},
        };
        // slideId = `layout04-${slideId}`;
        break;
      case LayoutEnum.TITLE_ONLY:
        const filteredLayout5TextBoxes = Object.entries(textBoxesWithoutDefaultLayoutText)
          .filter(([key]) => {
            const isLayoutTextBox = (key.includes('default')) && (key.includes('Title') || key.includes('title'));
            // console.log('layout', isLayoutTextBox);
            return !(isLayoutTextBox);
          })
          .reduce((textBoxes, [key, textBox]) => {
            textBoxes[key] = textBox;
            return textBoxes;
          }, {});
        const AdditonalTextBoxeskeys5 = Object.keys(filteredLayout5TextBoxes);
        slideData = {
          slideBackgroundColor: currentSlideData ? currentSlideData.slideBackgroundColor : '#fff',
          textBoxIds: [titleId, ...AdditonalTextBoxeskeys5],
          textBox: {
            ...filteredLayout5TextBoxes,
            [titleId]: {
              delta: {
                ops: [{ insert: defaultLayoutTitle ? defaultLayoutTitle : "Click to add title" }, { insert: "\n" }],
              },
              styles: {
                width: "93%",
                height: "11%",
                padding: "5px 10px",
                position: "absolute",
                top: "8%",
                left: "3.5%",
              },
            },
          },
          imageIds: currentSlideData ? Object.keys(currentSlideData.image) : [],
          image: currentSlideData.image ? currentSlideData.image : {},
          shapes: currentSlideData.shapes ? currentSlideData.shapes : {},
          speakerNotes: currentSlideData.speakerNotes ? currentSlideData.speakerNotes : {},
          additionalData: currentSlideData?.additionalData ? currentSlideData?.additionalData : {},
        };
        // slideId = `layout05-${slideId}`;
        break;
    }
    const existingMasterLayoutId = Object.keys(existingSlides).find(id => id.toLowerCase().includes(slideLayout.toLowerCase()));
    if (existingSlides[existingMasterLayoutId]) {
      let masterSlideData = modifyMasterSlideData(existingSlides[existingMasterLayoutId] || null);
      if (masterSlideData) {
        slideData = removeDuplicates(slideData, masterSlideData);
        slideData = filterOtherLayoutElements(slideData, slideLayout);
        const additionalData = slideData.additionalData || null;
        let filteredTextBoxIds = masterSlideData.textBoxIds.filter(textBoxId => !textBoxId.includes('layout'));
        let modifiedTextBoxes = {};
        for (const textBoxId of filteredTextBoxIds) {
          modifiedTextBoxes[textBoxId] = {
            ...masterSlideData.textBox[textBoxId],
            styles: { ...masterSlideData.textBox[textBoxId].styles, pointerEvents: 'none' },
          };
        }
        slideData = {
          ...slideData,
          slideBackgroundColor: !additionalData?.isSlideColorChanged ? masterSlideData.slideBackgroundColor :
            slideData.slideBackgroundColor,
          textBoxIds: [...slideData.textBoxIds, ...filteredTextBoxIds],
          textBox: { ...slideData.textBox, ...modifiedTextBoxes },
          imageIds: [...masterSlideData.imageIds],
          image: { ...slideData.image, ...masterSlideData.image },
          shapes: { ...slideData.shapes, ...masterSlideData.shapes },
          speakerNotes: slideData.speakerNotes,
        }
      }
    }
    slideData = {
      ...slideData,
      layoutType: slideLayout,
    }
    const newSlideData = {
      slideId,
      slideData,
    };

    dispatch(slideAction.addSlide(newSlideData));
    dispatch(slideAction.setCurrentSlide(slideId));
    setTimeout(
      () =>
        getSnapshot().then((dataUrl) => {
          dispatch(
            slideAction.saveMirror({
              dataId: slideId,
              mirrorSlideDataUrl: dataUrl,
            })
          );
          emitSlideAdd({ roomId: id, mirrorData: { dataId: slideId, mirrorSlideDataUrl: dataUrl }, slideData: { slideId, slideData } });
          const updatedSlides = getUpdatedSlides(newSlideData, 'slide', existingSlides);
          const data = {
            slides: updatedSlides,
            mirrorSlides: mirrorSlides
          }
          addNewSlideInDatabase(id, slideId, slideData, dispatch, data);
        }),
      0
    );
  };

  function handleMouseDown(e) {
    e.preventDefault();
    if (isMasterSlide && masterSlideId) {
      currentSlideId = masterSlideId;
    }
    draw = true;
    tempBox = document.createElement("div");
    tempBox.setAttribute("id", "temp");
    tempBox.classList.add("border");
    lockScreen.appendChild(tempBox);
    x1 = e.offsetX;
    y1 = e.offsetY;
  }
  function handleMouseUp(e) {
    lockScreen.removeChild(tempBox);
    // slideContainer.removeEventListener("mousemove", handleMouseMove);
    if (x2 === 0 || x2 === undefined) {
      x2 = x1 + 500;
    }
    if (y2 === 0 || y2 === undefined) {
      y2 = y1 + 50;
    }
    const textId = "text" + v4();
    let elementData = {
      textId: textId,
      style: {
        width: ((x2 - x1) / pageWidth) * 100 + "%",
        height: ((y2 - y1) / pageHeight) * 100 + "%",
        position: "absolute",
        padding: "5px 10px",
        left: (x1 / pageWidth) * 100 + "%",
        top: (y1 / pageHeight) * 100 + "%",
        zIndex: 100,
      },
      currentSlideId: currentSlideId,
    }
    if (!isMasterSlide) {
      dispatch(slideAction.addTextBox(elementData));
      emitElementAdd({ roomId: id, elementType: "text", elementData: elementData });
      const updatedSlides = getUpdatedSlides(elementData, 'text', existingSlides)
      let data = {
        slides: updatedSlides,
        mirrorSlides: mirrorSlides
      }
      createNewElementInDatabase(id, currentSlideId, textId, {
        delta: {
          ops: [{ insert: "" }]
        }, styles: elementData.style
      }, 'text', dispatch, data);
    }
    else if (isMasterSlide) {
      const isMainMasterSlide = !Object.values(LayoutEnum).some(layout => currentSlideId.toLowerCase().includes(layout.toLowerCase()));
      if (isMainMasterSlide) {
        dispatch(slideAction.addTextBox({ ...elementData, currentSlideId: masterSlideId }));
        Object.keys(existingSlides).forEach((slideId) => {
          const isMasterLayout = Object.values(LayoutEnum).some((enumValue) =>
            slideId.includes(enumValue)
          );
          //adding master-texbox in master-layouts
          if (isMasterLayout) {
            let payload = {
              ...elementData,
              style: { ...elementData.style, pointerEvents: 'none' },
              currentSlideId: slideId
            }
            dispatch(slideAction.addTextBox(payload));
          }
        });
        slideAPI.createMultipleNewElementInDatabase(id, textId, {
          delta: {
            ops: [{ insert: "" }]
          }, styles: elementData.style
        }, 'text');
      }
      else {
        const elementId = `${masterSlideId.split('-')[2]}-${elementData.textId}`;
        dispatch(slideAction.addTextBox({
          ...elementData, currentSlideId: masterSlideId,
          textId: elementId
        }));
        slideAPI.createMultipleNewElementInDatabase(id, elementId, {
          delta: {
            ops: [{ insert: "" }]
          }, styles: elementData.style
        }, 'text', currentSlideData.layoutType);
      }
      // handleMasterSlideUpdate('textbox', elementData);
    }
    slideContent.style.cursor = "default";
    draw = false;
    clickCount = 0;
    x2 = 0;
    y2 = 0;
    creatingTextbox = false;
    slideContent.removeChild(lockScreen);
    document.removeEventListener("click", handleDocumentClick);
  }
  function handleMouseMove(e) {
    if (draw) {
      x2 = e.offsetX;
      y2 = e.offsetY;
      tempBox.setAttribute(
        "style",
        `width: ${x2 - x1}px;height: ${y2 - y1
        }px; position: absolute; left:${x1}px; top:${y1}px; border: 2px solid #6eb7fa;`
      );
    }
  }
  const handleDocumentClick = (e) => {
    if (creatingTextbox && !e.target.id.includes('create-textbox')) {
      if (lockScreen && slideContent.contains(lockScreen)) {
        e.stopPropagation();
        slideContent.removeChild(lockScreen);
        draw = false;
        clickCount = 0;
        x2 = 0;
        y2 = 0;
        creatingTextbox = false;
        document.removeEventListener("click", handleDocumentClick);
      }
    }
  }

  const addTextBoxHandler = (e) => {
    clickCount = clickCount + 1;
    if (!currentSlideId || clickCount > 1) {
      return;
    }
    if (e.target.tagName === 'path') {
      e.target.setAttribute('id', 'create-textbox');
    }
    slideContainer = document.querySelector("#mainContainer");
    slideContent = document.getElementById("slide01");
    if (isMasterSlide) {
      slideContent = document.getElementById("master-slide");
    }
    lockScreen = document.createElement("div");
    lockScreen.setAttribute("style", "width:100%; height:100%; position: absolute; background: transparent; cursor: crosshair; z-index: 9999999999");
    slideContent.appendChild(lockScreen);
    lockScreen.addEventListener("mousedown", handleMouseDown, {
      once: true,
    });
    lockScreen.addEventListener("mousemove", handleMouseMove, false);
    lockScreen.addEventListener("mouseup", handleMouseUp, { once: true });
    creatingTextbox = true;
    document.addEventListener("click", handleDocumentClick);
  };

  const handleImageUpload = (e) => {
    if (!currentSlideId) {
      return;
    }
    else if (isMasterSlide && masterSlideId) {
      currentSlideId = masterSlideId;
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const imageId = "image" + v4();
      const imageb64 = reader.result;
      const style = { width: '20%', height: '20%', position: 'absolute', top: '50%', left: '50%', zIndex: 100 };
      if (!isMasterSlide) {
        dispatch(
          slideAction.addImage({
            imageId,
            imageb64,
            style,
            currentSlideId,
          })
        );
        const updatedSlides = getUpdatedSlides({
          imageId,
          imageb64,
          style,
          currentSlideId,
        }, 'image', existingSlides);
        let data = {
          slides: updatedSlides,
          mirrorSlides: mirrorSlides
        }
        createNewElementInDatabase(id, currentSlideId, imageId, { imageb64: imageb64, styles: style }, 'image', dispatch, data);
      }
      else if (isMasterSlide) {
        let payload = {
          imageId,
          imageb64,
          style,
          currentSlideId: masterSlideId,
        }
        const isMainMasterSlide = !Object.values(LayoutEnum).some(layout => currentSlideId.toLowerCase().includes(layout.toLowerCase()));
        if (isMainMasterSlide) {
          dispatch(slideAction.addImage(payload));
          Object.keys(existingSlides).forEach((slideId) => {
            const isMasterLayout = Object.values(LayoutEnum).some((enumValue) =>
              slideId.includes(enumValue)
            );
            //master image to all master-layouts
            if (isMasterLayout) {
              dispatch(slideAction.addImage({
                ...payload,
                style: { ...style, pointerEvents: 'none' },
                currentSlideId: slideId
              }));
            }
          });
          slideAPI.createMultipleNewElementInDatabase(id, imageId, { imageb64: imageb64, styles: style }, 'image');
        }
        else {
          const elementId = `${masterSlideId.split('-')[2]}-${payload.imageId}`;
          dispatch(slideAction.addImage({ ...payload, imageId: elementId }));
          slideAPI.createMultipleNewElementInDatabase(id, elementId, { imageb64: imageb64, styles: style },
            'image', currentSlideData.layoutType);
        }
      }
      emitElementAdd({ roomId: id, elementType: "image", elementData: { imageId, imageb64, style, currentSlideId } });
      // console.log(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
      e.target.value = "";
    }
  };
  const pointerEventsStyle = {
    // pointerEvents: documentInformation.rightType==1 ? "none" : "all",
    display: isReadOnly ? "none" : "flex",
  };
  const handelAddMasterSlide = () => {
    dispatch(slideAction.setIsMasterSlide(true));
    const existingMasterSlideId = Object.keys(existingSlides).find(slideId => slideId.toLowerCase().includes("master"));

    if (existingMasterSlideId) {
      dispatch(slideAction.setMasterSlideId(existingMasterSlideId));
      dispatch(slideAction.setCurrentSlide(existingMasterSlideId));
    } else {
      const slideId = "master-slide" + v4();
      let slideData = {
        slideBackgroundColor: '#fff',
        textBoxIds: [],
        textBox: {},
        imageIds: [],
        image: {},
        shapes: {},
        speakerNotes: {},
      };
      const newSlideData = {
        slideId,
        slideData,
      };
      dispatch(slideAction.setMasterSlideId(slideId));
      dispatch(slideAction.setCurrentSlide(slideId));
      dispatch(slideAction.addSlide(newSlideData));
      addNewSlideInDatabase(id, slideId, slideData, dispatch);

      //Add all other master-layouts
      Object.values(LayoutEnum).forEach((slideId) => {
        let newSlideId = `master-${slideId + '-' + v4()}`;
        let slideData = layoutsArray.filter((layout) => layout.key === slideId)?.[0]?.structure;
        const newSlideData = {
          slideId: newSlideId,
          slideData: {
            ...slideData,
            layoutType: slideId
          },
        };
        dispatch(slideAction.addSlide(newSlideData));
        slideAPI.addNewSlideInDatabase(id, newSlideId, { ...slideData, layoutType: slideId });
      });
    }
  }

  async function handleMasterSlideUpdate(eleType, elementData) {
    let mirrorDataArray = [];
    const slideKeys = Object.keys(existingSlides).filter(slideId => !slideId.includes('master'));
    for (let slideId of slideKeys) {
      try {
        if (eleType === 'image') {
          dispatch(
            slideAction.addImage({
              imageId: elementData.imageId,
              imageb64: elementData.imageb64,
              style: {
                ...elementData.style,
                pointerEvents: slideId.includes('master') ? 'all' : 'none'
              },
              currentSlideId: slideId,
            })
          );
        }
        else if (eleType === 'textbox') {
          dispatch(slideAction.addTextBox({
            ...elementData,
            style: slideId.includes('master') ? { ...elementData.style, pointerEvents: 'all' } :
              { ...elementData.style, pointerEvents: 'none' },
            currentSlideId: slideId
          }));
        }
        dispatch(slideAction.setCurrentSlide(slideId));
        await delay(500);
        const dataUrl = await getSnapshot();
        dispatch(
          slideAction.saveMirror({
            dataId: slideId,
            mirrorSlideDataUrl: dataUrl,
          })
        );
        mirrorDataArray.push({
          imageId: slideId,
          b64Data: dataUrl,
        })
      }
      catch (err) {
        console.error('Crash....', err);
      }
    }
    dispatch(slideAction.setCurrentSlide(masterSlideId));
    if (eleType === 'image') {
      slideAPI.createMultipleNewElementInDatabase(id, elementData.imageId, {
        imageb64: elementData.imageb64,
        styles: elementData.style
      }, 'image');
    }
    else if (eleType === 'textbox') {
      slideAPI.createMultipleNewElementInDatabase(id, elementData.textId, {
        delta: {
          ops: [{ insert: "" }]
        }, styles: {
          ...elementData.style,
          pointerEvents: 'none'
        },
      }, 'text');
    }
    slideAPI.saveMultipleMirrorInDatabase(id, mirrorDataArray);
    dispatch(slideAction.setCurrentSlide(masterSlideId));
  }
  function delay(t) {
    return new Promise(resolve => setTimeout(resolve, t));
  }

  /*************************** ****************************/

  return (
    <>
      <div className={classes.toolbar} style={{ ...pointerEventsStyle }} id='my-toolbar'>
        <div className={classes["slide-toolbar"]}>
          <Tooltip overlayInnerStyle={{ width: '60px', minHeight: '20px', fontSize: '10px', padding: '5px', textAlign: "center", backgroundColor: 'black', lineHeight: '10px' }} placement="bottom" title={"New Slide"}>
            <button className={classes['toolbar-btn']} onClick={addSlideHandler}>
              <AddIcon fontSize="medium" alt={"toolbar-icon"} style={{ verticalAlign: 'top' }} />
            </button>
          </Tooltip>
          {/* <img alt={"toolbar-icon"} className={classes['toolbar-btn']} src={addSlide} onClick={addSlideHandler} /> */}
          {/* <img alt={"toolbar-icon"} src={undo} />
          <img alt={"toolbar-icon"} src={redo} />
          <img alt={"toolbar-icon"} src={print} />
          <img alt={"toolbar-icon"} src={paintFormat} /> */}
          <div className={classes.separator}></div>
          {/* <img alt={"toolbar-icon"} src={zoom} />
        <div className={classes.separator}></div> */}
          {/* <img alt={"toolbar-icon"} src={cursor} /> */}
          <button className={classes["toolbar-btn"]} id='create-textbox' onClick={(e) => addTextBoxHandler(e)}>
            <Tooltip id='create-textbox' overlayInnerStyle={{ width: '60px', minHeight: '20px', fontSize: '10px', padding: '5px', textAlign: "center", backgroundColor: 'black', lineHeight: '10px' }} placement="bottom" title={"Text Box"}>
              <AddTextIcon
                id='create-textbox'
                fontSize="small"
                style={{ verticalAlign: "top" }}
                alt={"toolbar-icon"}
              />
            </Tooltip>
          </button>
          <div className={classes.separator}></div>
          <button className={`image-upload ${classes["toolbar-btn"]}`}>
            <label htmlFor="imageUpload">
              <Tooltip overlayInnerStyle={{ width: '60px', minHeight: '20px', fontSize: '10px', padding: '5px', textAlign: "center", backgroundColor: 'black', lineHeight: '10px' }} placement="bottom" title={"Add Image"}>
                <AddImage
                  fontSize="medium"
                  // style={{ }}
                  style={{ width: "25px", height: "22px", verticalAlign: "top" }}
                  alt={"toolbar-icon"}
                />
              </Tooltip>
            </label>
            <input
              id="imageUpload"
              type="file"
              accept="image/png, image/jpeg, image/svg, image/jpg"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
            />
          </button>
          <div className={classes.separator}></div>
          <Tooltip overlayInnerStyle={{ width: '60px', minHeight: '20px', fontSize: '10px', padding: '5px', textAlign: "center", backgroundColor: 'black', lineHeight: '10px' }} placement="bottom" title={"Shapes"}>
            <div className={classes['toolbar-btn']}>
              <ShapesDropdown currentSlideId={currentSlideId} presentationId={id} />
              {/* <img alt={"toolbar-icon"} src={shapeIcon} onClick={addCircle} /> */}
            </div>
          </Tooltip>

          <div className={classes.separator}></div>
          <div className={classes['toolbar-btn']} style={{ paddingBottom: '0px' }}>
            <ColorPickerDropDown classname={classes["toolbar-btn"]} isMasterSlide={isMasterSlide} masterSlideId={masterSlideId} />
          </div>
          <div className={classes.separator}></div>
          <button className={classes["toolbar-btn"]} id="layout-btn">
            <LayoutDropDown addSlideLayout={addSlideLayout} id="layout-btn" />
          </button>
          <div className={classes.separator}></div>
          <span onClick={handelAddMasterSlide} style={{ fontSize: 12, fontWeight: 600, overflow: "hidden", whiteSpace: 'nowrap', textOverflow: 'ellipsis', cursor: "pointer" }}>Master Slide</span>
          <div className={classes.separator}></div>
        </div>
        <div id="quillToolbar" className={classes.sticky}></div>
        {/* <div className={classes.separator}></div>
      <div className={classes["toolbar-btn"]}>Theme</div>
      <div className={classes.separator}></div>
      <div className={classes["toolbar-btn"]}>Transition</div> */}
      </div>
    </>
  );
};