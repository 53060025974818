const LayoutEnum = {
    BLANK: 'layout-Blank',
    TITLE_BODY: 'layout-TitleBody',
    TITLE_TWO_COLUMNS: 'layout-TitleTwoColumns',
    SECTION_HEADER: 'layout-SectionHeader',
    TITLE_ONE_COLUMN: 'layout-TitleOneColumns',
    TITLE_ONLY: 'layout-TitleOnly',
  };
  
  export default LayoutEnum;
  