import React from "react";
import { Menu, Dropdown, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { v4 } from "uuid";
import { slideAction } from "../../../store/slideSlice";
import { useDispatch, useSelector } from "react-redux";
import shapeIcon from "../../../assets/shapes.svg";
import basicCircle from '../../../assets/shapes/basicCircle.svg'
import basicTriangle from '../../../assets/shapes/basicTriangle.svg'
import basicRectangle from '../../../assets/shapes/basicRectangle.svg'
import rightTriangle from '../../../assets/shapes/rightTriangle.svg'
import basicStar from "../../../assets/shapes/basicStar.svg";
import calloutRect from '../../../assets/shapes/calloutRect.svg';
import cylinder from '../../../assets/shapes/cylinder.svg';
import heart from '../../../assets/shapes/heart.svg';
import plus from '../../../assets/shapes/plus.svg';
import diamond from '../../../assets/shapes/diamond.svg';
import squre2 from '../../../assets/shapes/Squre2.svg';
import oval from '../../../assets/shapes/oval.svg';
import singleCornerRectangle from '../../../assets/shapes/single-corner-rectangle.svg';
import roundedRectangle from '../../../assets/shapes/rounded-rectangle.svg';
import parallelogram from '../../../assets/shapes/parallelogram.svg';
import Trapezoid from '../../../assets/shapes/Trapezoid.svg';
import SubMenu from "antd/lib/menu/SubMenu";
import { emitElementAdd } from "../../../Socket/Socket";
import { slideAPI } from "../../../store/api";
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getSnapshot } from "../../../utils/utils";
import LayoutEnum from "../../../utils/layoutEnum";
import { getUpdatedSlides } from "../../../utils/helper";

export const ShapesDropdown = ({ currentSlideId, presentationId }) => {
  const {
    createNewElementInDatabase,
    saveMirrorInDatabase,
  } = slideAPI;

  const dispatch = useDispatch();
  const { id } = useParams();

  const isMasterSlide = useSelector((state) => state.slide.isMasterSlide);
  const masterSlideId = useSelector(state => state.slide.masterSlideId);
  const existingSlides = useSelector((state) => state.slide.slides);
  const mirrorSlides = useSelector((state) => state.slide.mirrorSlides);

  const addShape = (e, type, shapeImg) => {
    if (!currentSlideId) {
      return;
    }
    let draw = false,
      tempBox,
      slideContainer,
      slideContent,
      x1,
      y1,
      x2,
      y2;
    let lockScreen = document.createElement("div");
    lockScreen.setAttribute("style", "width:100%; height:100%; position: absolute; background: transparent; cursor: crosshair; z-index: 9999999999");
    // slideContainer = document.querySelector("#mainContainer");
    slideContent = document.getElementById("slide01");
    if (isMasterSlide) {
      slideContent = document.getElementById("master-slide");
    }
    slideContent.appendChild(lockScreen);
    const pageWidth = window.screen.width > 2200 ? 1800 : window.screen.width > 1540 ? 1200 : window.screen.width > 1200 ? 960 : window.screen.width > 992 ? 848 : window.screen.width > 768 ? 700 : window.screen.width > 490 ? 490 : 300;
    const pageHeight = window.screen.width > 2200 ? 1020 : window.screen.width > 1540 ? 700 : window.screen.width > 1200 ? 540 : window.screen.width > 992 ? 500 : window.screen.width > 768 ? 400 : window.screen.width > 490 ? 290 : 180;

    function mouseDown(e) {
      e.preventDefault();
      draw = true;
      x1 = e.offsetX;
      y1 = e.offsetY;
      tempBox = document.createElement("img");
      tempBox.setAttribute("style", `width: ${0}px;height: ${0}px; position: absolute; left:${x1}px; top:${y1}px;`);
      tempBox.setAttribute("src", shapeImg)
      tempBox.setAttribute("alt", "...")
      tempBox.setAttribute("id", "temp");
      //  tempBox.classList.add("border");
      lockScreen.appendChild(tempBox);
    }
    function mouseMove(e) {
      // setCordinate((prev) => {})
      if (draw) {
        x2 = e.offsetX;
        y2 = e.offsetY;
        tempBox.setAttribute("style", `width: ${x2 - x1}px;height: ${y2 - y1}px; position: absolute; left:${x1}px; top:${y1}px;`);
        // console.log(cordX, cordY);
      }
    }
    function mouseUp(e) {
      lockScreen.removeChild(tempBox);
      if (isMasterSlide && masterSlideId) {
        currentSlideId = masterSlideId;
      }
      // slideContainer.removeEventListener('mousemove', mouseMove);
      if (x2 === 0 || x2 === undefined) {
        x2 = x1 + 200;
      }
      if (y2 === 0 || y2 === undefined) {
        y2 = y1 + 200;
      }
      const shapeId = type + 'shape' + v4();
      let elementData = {
        shapeId,
        currentSlideId,
        style: { width: (x2 - x1) / pageWidth * 100 + '%', height: (y2 - y1) / pageHeight * 100 + '%', position: 'absolute', left: (x1) / pageWidth * 100 + '%', top: (y1) / pageHeight * 100 + '%' }
      }
      if (!isMasterSlide) {
        dispatch(slideAction.addShape(elementData));
        const updatedSlides = getUpdatedSlides(elementData,'shape',existingSlides);
        let data = {
          slides: updatedSlides,
          mirrorSlides: mirrorSlides
        }
        createNewElementInDatabase(presentationId, currentSlideId, shapeId, {
          delta: {
            ops: [{ insert: "" }]
          }, styles: elementData.style
        }, 'shape', dispatch, data);
      }
      else if (isMasterSlide) {
        const isMainMasterSlide = !Object.values(LayoutEnum).some(layout => currentSlideId.toLowerCase().includes(layout.toLowerCase()));
        if (isMainMasterSlide) {
          dispatch(slideAction.addShape(elementData));
          Object.keys(existingSlides).forEach((slideId) => {
            const isMasterLayout = Object.values(LayoutEnum).some((enumValue) =>
              slideId.includes(enumValue)
            );
            if (isMasterLayout) {
              let payload = {
                ...elementData,
                style: { ...elementData.style, pointerEvents: 'none' },
                currentSlideId: slideId
              }
              dispatch(slideAction.addShape(payload));
            }
          });
          slideAPI.createMultipleNewElementInDatabase(presentationId, shapeId, {
            delta: {
              ops: [{ insert: "" }]
            }, styles: elementData.style
          }, 'shape');
        }
        else {
          const elementId = `${currentSlideId.split('-')[2]}-${elementData.shapeId}`;
          dispatch(slideAction.addShape({ ...elementData, shapeId: elementId }));
          slideAPI.createMultipleNewElementInDatabase(presentationId, elementId, {
            delta: {
              ops: [{ insert: "" }]
            }, styles: elementData.style
          }, 'shape',existingSlides[currentSlideId].layoutType);
        }
        // handleMasterSlideUpdate(elementData);
      }
      emitElementAdd({ roomId: presentationId, elementType: "shape", elementData: elementData });
      // dispatch(slideAction.addCircle({circleId: circleId , style:{ width: (x2-x1)/960 * 100 +'%', height: (y2-y1)/540 * 100 + '%', borderRadius: "50", position: 'absolute', left: (x1)/960 * 100 +'%', top: (y1)/540 * 100 +'%'}}))
      slideContent.style.cursor = 'default';
      draw = false;
      x2 = 0;
      y2 = 0;
      slideContent.removeChild(lockScreen);
    }

    lockScreen.addEventListener('mousedown', mouseDown, { once: true });
    lockScreen.addEventListener('mousemove', mouseMove, false);
    lockScreen.addEventListener('mouseup', mouseUp, { once: true });

  }
  function delay(t) {
    return new Promise(resolve => setTimeout(resolve, t));
  }

  async function handleMasterSlideUpdate(elementData) {
    let mirrorDataArray = [];
    const slideKeys = Object.keys(existingSlides).filter(slideId => !slideId.includes('master'));
    for (let slideId of slideKeys) {
      try {
        dispatch(slideAction.addShape({
          ...elementData,
          style: {
            ...elementData.style,
            pointerEvents: 'none'
          },
          currentSlideId: slideId
        }));
        dispatch(slideAction.setCurrentSlide(slideId));
        await delay(500);
        const dataUrl = await getSnapshot();
        dispatch(
          slideAction.saveMirror({
            dataId: slideId,
            mirrorSlideDataUrl: dataUrl,
          })
        );
        mirrorDataArray.push({
          imageId: slideId,
          b64Data: dataUrl,
        })
      }
      catch (err) {
        console.error('Crash....', err);
      }
    }
    slideAPI.createMultipleNewElementInDatabase(id, elementData.shapeId, {
      delta: {
        ops: [{ insert: "" }]
      }, styles: {
        ...elementData.style,
        pointerEvents: 'none'
      },
    }, 'shape');
    slideAPI.saveMultipleMirrorInDatabase(id, mirrorDataArray);
    dispatch(slideAction.setCurrentSlide(masterSlideId));
  }
  // const addRectangle = () =>{
  //     slideContainer = document.querySelector("#mainContainer");
  //     slideContent = document.getElementById("slide01");
  //     slideContent.style.cursor = 'crosshair';

  //     function rectangleMouseDown(e){
  //       e.preventDefault();
  //       draw = true;
  //       x1= e.offsetX;
  //       y1= e.offsetY;
  //        tempBox = document.createElement("img");
  //        tempBox.setAttribute("style",`width: ${0}px;height: ${0}px; position: absolute; left:${x1}px; top:${y1}px;`);
  //        tempBox.setAttribute("src",basicRectangle)
  //        tempBox.setAttribute("id","temp");
  //       //  tempBox.classList.add("border");
  //        slideContent.appendChild(tempBox);
  //      }
  //      function rectangleMouseMove(e){
  //        // setCordinate((prev) => {})
  //        if(draw){
  //         x2 = e.offsetX;
  //         y2 = e.offsetY;
  //        tempBox.setAttribute("style",`width: ${x2-x1}px;height: ${y2-y1}px; position: absolute; left:${x1}px; top:${y1}px;`);
  //          // console.log(cordX, cordY);
  //        }
  //      }
  //      function rectangleMouseUp(e){
  //       slideContent.removeChild(tempBox);
  //       slideContainer.removeEventListener('mousemove', rectangleMouseMove);
  //       const shapeId = 'rectangle' + v4();
  //       dispatch(slideAction.addShape({shapeId, style:{ width: (x2-x1)/960 * 100 +'%', height: (y2-y1)/540 * 100 + '%', position: 'absolute', left: (x1)/960 * 100 +'%', top: (y1)/540 * 100 +'%'}}))
  //       // dispatch(slideAction.addCircle({circleId: circleId , style:{ width: (x2-x1)/960 * 100 +'%', height: (y2-y1)/540 * 100 + '%', borderRadius: "50", position: 'absolute', left: (x1)/960 * 100 +'%', top: (y1)/540 * 100 +'%'}}))
  //       slideContent.style.cursor = 'default';
  //        draw = false;
  //        x2 = 0;
  //        y2 = 0;
  //      }

  //      slideContainer.addEventListener('mousedown', rectangleMouseDown, {once: true});
  //      slideContainer.addEventListener('mousemove', rectangleMouseMove, false);
  //      slideContainer.addEventListener('mouseup', rectangleMouseUp, {once: true});

  // }

  // const addCircle = () =>{
  //     slideContainer = document.querySelector("#mainContainer");
  //     slideContent = document.getElementById("slide01");
  //     slideContent.style.cursor = 'crosshair';

  //     function circleMouseDown(e){
  //       e.preventDefault();
  //       draw = true;
  //        tempBox = document.createElement("div");
  //        tempBox.setAttribute("id","temp");
  //       //  tempBox.classList.add("border");
  //        slideContent.appendChild(tempBox);
  //        x1= e.offsetX;
  //        y1= e.offsetY;
  //      }
  //      function circleMouseMove(e){
  //        // setCordinate((prev) => {})
  //        if(draw){
  //         x2 = e.offsetX;
  //         y2 = e.offsetY;
  //        tempBox.setAttribute("style",`width: ${x2-x1}px;height: ${y2-y1}px; position: absolute; left:${x1}px; top:${y1}px; border-radius: 50%; border: 2px solid #000;`);
  //          // console.log(cordX, cordY);
  //        }
  //      }
  //      function circleMouseUp(e){
  //       slideContent.removeChild(tempBox);
  //       slideContainer.removeEventListener('mousemove', circleMouseMove);
  //       const shapeId = 'circle' + v4();
  //       dispatch(slideAction.addShape({shapeId, style:{ width: (x2-x1)/960 * 100 +'%', height: (y2-y1)/540 * 100 + '%', position: 'absolute', left: (x1)/960 * 100 +'%', top: (y1)/540 * 100 +'%'}}))
  //       // dispatch(slideAction.addCircle({circleId: circleId , style:{ width: (x2-x1)/960 * 100 +'%', height: (y2-y1)/540 * 100 + '%', borderRadius: "50", position: 'absolute', left: (x1)/960 * 100 +'%', top: (y1)/540 * 100 +'%'}}))
  //       slideContent.style.cursor = 'default';
  //        draw = false;
  //        x2 = 0;
  //        y2 = 0;
  //      }

  //      slideContainer.addEventListener('mousedown', circleMouseDown, {once: true});
  //      slideContainer.addEventListener('mousemove', circleMouseMove, false);
  //      slideContainer.addEventListener('mouseup', circleMouseUp, {once: true});

  //   }

  // const addTriangle = () =>{
  //     slideContainer = document.querySelector("#mainContainer");
  //     slideContent = document.getElementById("slide01");
  //     slideContent.style.cursor = 'crosshair';

  //     function triangleMouseDown(e){
  //       e.preventDefault();
  //       draw = true;
  //       x1= e.offsetX;
  //       y1= e.offsetY;
  //        tempBox = document.createElement("img");
  //        tempBox.setAttribute("style",`width: 0px; height: 0px; position: absolute; left:${x1}px; top:${y1}px;`);
  //        tempBox.setAttribute('src',basicTriangle);
  //        tempBox.setAttribute("id","temp");
  //       //  tempBox.classList.add("border");
  //        slideContent.appendChild(tempBox);
  //      }
  //      function triangleMouseMove(e){
  //        // setCordinate((prev) => {})
  //        if(draw){
  //         x2 = e.offsetX;
  //         y2 = e.offsetY;
  //        tempBox.setAttribute("style",`width: ${x2-x1}px;height: ${y2-y1}px; position: absolute; left:${x1}px; top:${y1}px;`);
  //          // console.log(cordX, cordY);
  //        }
  //      }
  //      function triangleMouseUp(e){
  //       slideContent.removeChild(tempBox);
  //       slideContainer.removeEventListener('mousemove', triangleMouseMove);
  //       const shapeId = 'triangle' + v4();
  //       dispatch(slideAction.addShape({shapeId, style:{ width: (x2-x1)/960 * 100 +'%', height: (y2-y1)/540 * 100 + '%', position: 'absolute', left: (x1)/960 * 100 +'%', top: (y1)/540 * 100 +'%'}}))
  //       // dispatch(slideAction.addCircle({circleId: circleId , style:{ width: (x2-x1)/960 * 100 +'%', height: (y2-y1)/540 * 100 + '%', borderRadius: "50", position: 'absolute', left: (x1)/960 * 100 +'%', top: (y1)/540 * 100 +'%'}}))
  //       slideContent.style.cursor = 'default';
  //        draw = false;
  //        x2 = 0;
  //        y2 = 0;
  //      }

  //      slideContainer.addEventListener('mousedown', triangleMouseDown, {once: true});
  //      slideContainer.addEventListener('mousemove', triangleMouseMove, false);
  //      slideContainer.addEventListener('mouseup', triangleMouseUp, {once: true});

  // }
  // const addCalloutRect = () =>{
  //     slideContainer = document.querySelector("#mainContainer");
  //     slideContent = document.getElementById("slide01");
  //     slideContent.style.cursor = 'crosshair';

  //     function calloutRectMouseDown(e){
  //       e.preventDefault();
  //       draw = true;
  //        tempBox = document.createElement("img");
  //        tempBox.setAttribute("style",`width: 0px;height: 0px; position: absolute; left:${x1}px; top:${y1}px;`);
  //        tempBox.setAttribute('src',calloutRect);
  //        tempBox.setAttribute("id","temp");
  //       //  tempBox.classList.add("border");
  //        slideContent.appendChild(tempBox);
  //        x1= e.offsetX;
  //        y1= e.offsetY;
  //      }
  //      function calloutRectMouseMove(e){
  //        // setCordinate((prev) => {})
  //        if(draw){
  //         x2 = e.offsetX;
  //         y2 = e.offsetY;
  //        tempBox.setAttribute("style",`width: ${x2-x1}px;height: ${y2-y1}px; position: absolute; left:${x1}px; top:${y1}px;`);
  //          // console.log(cordX, cordY);
  //        }
  //      }
  //      function calloutRectMouseUp(e){
  //       slideContent.removeChild(tempBox);
  //       slideContainer.removeEventListener('mousemove', calloutRectMouseMove);
  //       const shapeId = 'callout' + v4();
  //       dispatch(slideAction.addShape({shapeId, style:{ width: (x2-x1)/960 * 100 +'%', height: (y2-y1)/540 * 100 + '%', position: 'absolute', left: (x1)/960 * 100 +'%', top: (y1)/540 * 100 +'%'}}))
  //       // dispatch(slideAction.addCircle({circleId: circleId , style:{ width: (x2-x1)/960 * 100 +'%', height: (y2-y1)/540 * 100 + '%', borderRadius: "50", position: 'absolute', left: (x1)/960 * 100 +'%', top: (y1)/540 * 100 +'%'}}))
  //       slideContent.style.cursor = 'default';
  //        draw = false;
  //        x2 = 0;
  //        y2 = 0;
  //      }

  //      slideContainer.addEventListener('mousedown', calloutRectMouseDown, {once: true});
  //      slideContainer.addEventListener('mousemove', calloutRectMouseMove, false);
  //      slideContainer.addEventListener('mouseup', calloutRectMouseUp, {once: true});

  // }

  const menu = (
    <Menu key={"abc"}>
      {/* <SubMenu key={"xyz"} title="Shapes" id='shapes-menu'> */}
      <Menu.Item key="0" className="shape-menu-item" style={{ width: 'auto', margin: '0px' }} id='shapes-menu'>
        <div style={{ display: 'flex', justifyContent: "space-around" }}>
          <div className="shape-item" onClick={(e) => addShape(e, 'rectangle', basicRectangle)}><img src={basicRectangle} style={{ width: '20px', height: '20px' }} alt="..." /></div>
          <div className="shape-item" onClick={(e) => addShape(e, 'circle', basicCircle)}><img src={basicCircle} style={{ width: '20px', height: '20px' }} alt="..." /></div>
          <div className="shape-item" onClick={(e) => addShape(e, 'triangle', basicTriangle)}><img src={basicTriangle} style={{ width: '20px', height: '20px' }} alt="..." /></div>
          <div className="shape-item" onClick={(e) => addShape(e, 'oval', oval)}><img src={oval} style={{ width: '20px', height: '20px' }} alt="..." /></div>
          <div className="shape-item" onClick={(e) => addShape(e, 'trapezoid', Trapezoid)}><img src={Trapezoid} style={{ width: '20px', height: '20px' }} alt="..." /></div>
          <div className="shape-item" onClick={(e) => addShape(e, 'diamond', diamond)}><img src={diamond} style={{ width: '20px', height: '20px' }} alt="..." /></div>
          <div className="shape-item" onClick={(e) => addShape(e, 'rightTriangle', rightTriangle)}><img src={rightTriangle} style={{ width: '20px', height: '20px' }} alt="..." /></div>
          <div className="shape-item" onClick={(e) => addShape(e, 'callout', calloutRect)}><img src={calloutRect} style={{ width: '20px', height: '20px' }} alt="..." /></div>
          <div className="shape-item" onClick={(e) => addShape(e, 'star', basicStar)}><img src={basicStar} style={{ width: '20px', height: '20px' }} alt="..." /></div>
          <div className="shape-item" onClick={(e) => addShape(e, 'cylinder', cylinder)}><img src={cylinder} style={{ width: '20px', height: '20px' }} alt="..." /></div>
          {/* <div className="shape-item" onClick={(e)=>addShape(e,'heart',heart)}><img src={heart} style={{width: '20px', height: '20px'}} alt="..." /></div>
            <div className="shape-item" onClick={(e)=>addShape(e,'plus',plus)}><img src={plus} style={{width: '20px', height: '20px'}} alt="..." /></div>
            <div className="shape-item" onClick={(e)=>addShape(e,'squr2',squre2)}><img src={squre2} style={{width: '20px', height: '20px'}} alt="..." /></div>
            <div className="shape-item" onClick={(e)=>addShape(e,'single',singleCornerRectangle)}><img src={singleCornerRectangle} style={{width: '20px', height: '20px'}} alt="..." /></div>
            <div className="shape-item" onClick={(e)=>addShape(e,'round',roundedRectangle)}><img src={roundedRectangle} style={{width: '20px', height: '20px'}} alt="..." /></div>
            <div className="shape-item" onClick={(e)=>addShape(e,'parallelogram',parallelogram)}><img src={parallelogram} style={{width: '20px', height: '20px'}} alt="..." /></div> */}
        </div>
      </Menu.Item>
      {/* </SubMenu> */}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]} disabled={currentSlideId ? false : true}>
      <span
        style={{ color: "#626262" }}
        onClick={(e) => e.preventDefault()}
      >
        <CategoryOutlinedIcon
          fontSize="medium"
          style={{ width: "25px", height: "22px", verticalAlign: "top" }}
          alt={"toolbar-icon"}
        />
      </span>
    </Dropdown>
  );
};
