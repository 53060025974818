import React, { useEffect, useRef, useState } from "react";
import { Menu, Dropdown, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { slideAction } from "../../../store/slideSlice";
import { RgbaStringColorPicker } from "react-colorful";
import { getSnapshot } from "../../../utils/utils";
import { emitSlideColor } from "../../../Socket/Socket";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ColorFillIcon from "@mui/icons-material/FormatColorFill";
import { slideAPI } from "../../../store/api";
import debounce from 'lodash/debounce';
import LayoutEnum from "../../../utils/layoutEnum";
import { getUpdatedSlides } from "../../../utils/helper";

export const ColorPickerDropDown = ({ isMasterSlide, masterSlideId, classname }) => {
  const { id } = useParams();
  const [color, setColor] = useState("rgba(255, 255, 255, 1)");
  const existingSlides = useSelector((state) => state.slide.slides);
  const mirrorSlides = useSelector((state) => state.slide.mirrorSlides);
  const currentSlideId = useSelector((state) => state.slide.currentSlideId);
  const dispatch = useDispatch();

  useEffect(() => {
    let existingColor = existingSlides[currentSlideId]?.slideBackgroundColor || null;
    if (existingColor) {
      setColor(existingColor);
    }
  }, []);

  useEffect(() => {
    let existingColor = existingSlides[currentSlideId]?.slideBackgroundColor || null;
    if (!existingColor || existingColor === color) {
      return;
    }
    if (isMasterSlide) {
      const isMainMasterSlide = !Object.values(LayoutEnum).some(layout => currentSlideId.toLowerCase().includes(layout.toLowerCase()));
      if (isMainMasterSlide) {
        dispatch(
          slideAction.setSlideBackgroundColor({
            slideId: masterSlideId,
            backgroundColor: color,
          }));
        Object.keys(existingSlides).forEach((slideId) => {
          const isMasterLayout = Object.values(LayoutEnum).some((enumValue) =>
            slideId.includes(enumValue)
          );
          if (isMasterLayout) {
            const slideData = existingSlides[slideId] || null;
            if (slideData) {
              const additionalData = slideData.additionalData || null;
              // if no pre-define color applied
              if (!additionalData?.isSlideColorChanged) {
                dispatch(
                  slideAction.setSlideBackgroundColor({
                    slideId: slideId,
                    backgroundColor: color,
                  }));
              }
            }
          }
        });
      }
      else {
        dispatch(
          slideAction.setSlideBackgroundColor({
            slideId: masterSlideId,
            backgroundColor: color,
            isChangedByUser: true,
          }));
      }
      changeColorDebounce(id, color, isMainMasterSlide, existingSlides,masterSlideId);
      // handleMasterSlideUpdate(color);
      return;
    }
    let slideBackgroundColor = existingSlides?.[currentSlideId]?.slideBackgroundColor || null;
    let interval = setTimeout(() => {
      if (slideBackgroundColor && slideBackgroundColor !== color) {
        currentSlideId &&
          dispatch(
            slideAction.setSlideBackgroundColor({
              slideId: currentSlideId,
              backgroundColor: color,
              isChangedByUser:true,
            })
          );
        getSnapshot().then((dataUrl) => {
          // let image = new Image();
          // image.src = dataUrl;
          // image.setAttribute('style', "width='1000px'; height='540px';");
          // image.setAttribute('data-id', currentSlideId);
          // image.setAttribute('class', 'sider-slide');
          currentSlideId &&
            emitSlideColor({
              roomId: id,
              slideData: { slideId: currentSlideId, backgroundColor: color },
              mirrorData: { dataId: currentSlideId, mirrorSlideDataUrl: dataUrl },
            });
          dispatch(
            slideAction.saveMirror({
              dataId: currentSlideId,
              mirrorSlideDataUrl: dataUrl,
            })
          );
          // const cloneElement = document.querySelector(`[data-id = ${currentSlideId}]`);
          // cloneElement.replaceWith(image);
        });
        const payload = {
          backgroundColor: color,
          slideId: currentSlideId
        }
        const updatedSlides = getUpdatedSlides(payload, 'backgroundColor', existingSlides);
        const data = {
          slides: updatedSlides,
          mirrorSlides: mirrorSlides
        }
        slideAPI.updateSlideBackgroundInDatabase(id, currentSlideId, color, dispatch, data);
      }
    }, 300);
    return () => clearTimeout(interval);
  }, [color]);


  const changeColorDebounce = useRef(debounce((id, color, isMainMasterSlide, existingSlides,masterSlideId) => {
    const layoutType = isMainMasterSlide ? null : existingSlides[masterSlideId].layoutType;
    slideAPI.updateMultipleSlideBackgroundInDatabase(id, color, layoutType);
  }, 400)).current;

  const menu = (
    <Menu style={{ padding: "10px" }} id='bg-color-dropdwon'>
      <RgbaStringColorPicker colors={color} onChange={setColor} id='bg-color-dropdwon' />
    </Menu>
  );

  async function handleMasterSlideUpdate(color) {
    let mirrorDataArray = [];
    const slideKeys = Object.keys(existingSlides).filter(slideId => !slideId.includes('master'));
    for (let slideId of slideKeys) {
      try {
        dispatch(
          slideAction.setSlideBackgroundColor({
            slideId: slideId,
            backgroundColor: color,
          })
        );
        dispatch(slideAction.setCurrentSlide(slideId));
        await delay(500);
        const dataUrl = await getSnapshot();
        dispatch(
          slideAction.saveMirror({
            dataId: slideId,
            mirrorSlideDataUrl: dataUrl,
          })
        );
        mirrorDataArray.push({
          imageId: slideId,
          b64Data: dataUrl,
        })
      }
      catch (err) {
        console.error('Crash....', err);
      }
    }
    slideAPI.saveMultipleMirrorInDatabase(id, mirrorDataArray);
    slideAPI.updateMultipleSlideBackgroundInDatabase(id, color);
    dispatch(slideAction.setCurrentSlide(masterSlideId));
  }
  function delay(t) {
    return new Promise(resolve => setTimeout(resolve, t));
  }

  return (
    <Dropdown overlay={menu} trigger={["click"]} disabled={currentSlideId ? false : true} id='bg-color-dropdwon'>
      <a onClick={(e) => e.preventDefault()} style={{ color: "#626262" }}>
        <Tooltip
          overlayInnerStyle={{
            width: "fit-content",
            minHeight: "20px",
            fontSize: "10px",
            padding: "5px",
            textAlign: "center",
            backgroundColor: "black",
            lineHeight: "10px",
          }}
          placement="bottom"
          title={"Slide Background Color"}
        >
          <ColorFillIcon style={{ minHeight: "18x", height: "18px", verticalAlign: "top" }} />
        </Tooltip>
      </a>
    </Dropdown>
  );
};
